import React from "react";
import { connect } from "react-redux";
import { SFMediasState } from "../../redux/reducers/rootReducer";
import { INotificationListing } from "../../services/notification/notification.services.types";
import { notificationActions } from "../../redux/actions/notification/notification.actions";
import {
  EBox,
  ScrollContainer,
  Loader,
} from "./notificationInfiniteScroll.styles";
import { CircularProgress, Typography } from "@mui/material/";
import NotificationCard from "./notification";
import InfiniteScroll from "react-infinite-scroll-component";
import { withRouter } from "../../utils/hooks/withRouterHook";
import { ESFIcons, SFIcon } from "shootingflow.core";
import translation from "../../translations/fr.json";
interface INotificationHistoryViewProps extends IPropsFromDispatch {}

interface IPropsFromDispatch {
  loading?: boolean;
  notificationResults?: INotificationListing;
  newNotificationloading?: boolean;
  newNotificationsResults?: INotificationListing;
  getAllNotifications(page: number, loadMore: boolean): void;
  getNewNotifications(page: number, loadMore: boolean): void;
  updateNotificationsStatus(ids: Array<number>): void;
  updateNotificationsStatusState(): void;
  router?: any;
}

interface INotificationHistoryViewState {
  loading?: boolean;
  notificationResults?: INotificationListing;
}

class NotificationHistoryListing extends React.Component<
  INotificationHistoryViewProps,
  INotificationHistoryViewState
> {
  constructor(
    props:
      | INotificationHistoryViewProps
      | Readonly<INotificationHistoryViewProps>
  ) {
    super(props);
    this.state = {
      loading: false,
    };
  }

  UpdateNotifications() {
    var ids: number[];
    ids = [];
    this.state.notificationResults &&
      this.state.notificationResults.result.map((notif, i) => {
        if (notif.viewed === false) {
          ids.push(notif.id);
        }
      });
    this.props.updateNotificationsStatus(ids);
  }

  fetchMoreData() {
    if (this.props.router.location.pathname === "/notification-history") {
      if (this.state.notificationResults) {
        if (
          this.state.notificationResults.result.length !==
          this.state.notificationResults.totalCount
        ) {
          this.props.getAllNotifications(
            this.state.notificationResults?.currentPage + 1,
            true
          );
          this.UpdateNotifications();
        }
      }
    } else {
      if (this.state.notificationResults) {
        if (
          this.state.notificationResults.result.length !==
          this.state.notificationResults.totalCount
        ) {
          this.props.getNewNotifications(1, true);
          this.UpdateNotifications();
        }
      }
    }
  }

  componentDidMount() {
    if (this.props.router.location.pathname == "/notification-history") {
      this.props.getAllNotifications(1, false);
      this.UpdateNotifications();
    } else {
      this.props.getNewNotifications(1, false);
      this.UpdateNotifications();
    }
  }
  componentWillUnmount() {
    this.props.updateNotificationsStatusState();
  }
  static getDerivedStateFromProps(
    props: INotificationHistoryViewProps,
    state: INotificationHistoryViewState
  ): INotificationHistoryViewState {
    if (props.router.location.pathname === "/notification-history") {
      if (state.notificationResults !== props.notificationResults) {
        state.notificationResults = props.notificationResults;
        state.loading = props.loading;
      }
    } else {
      if (state.notificationResults !== props.newNotificationsResults) {
        state.notificationResults = props.newNotificationsResults;
        state.loading = props.newNotificationloading;
      }
    }
    return state;
  }

  render() {
    return (
      <ScrollContainer id="ScrollContainer">
        {this.state.notificationResults?.totalCount ? (
          <InfiniteScroll
            dataLength={this.state.notificationResults.result.length}
            next={() => this.fetchMoreData()}
            hasMore={
              this.state.notificationResults?.totalCount !==
              this.state.notificationResults.result.length
            }
            loader={
              this.state.loading && (
                <Loader container>
                  <CircularProgress />
                </Loader>
              )
            }
            scrollableTarget="ScrollContainer"
          >
            <>
              {this.state.notificationResults?.result &&
                this.state.notificationResults.result.length > 0 &&
                this.state.notificationResults.result.map((notif, i) => {
                  return <NotificationCard key={i} notification={notif} />;
                })}
            </>
          </InfiniteScroll>
        ) : (
          <EBox>
            <SFIcon icon={ESFIcons.Folder} size={"1.4rem"} />
            <Typography>{translation.notification.empty}</Typography>
          </EBox>
        )}
      </ScrollContainer>
    );
  }
}

const mapStateToDispatch = (state: SFMediasState) => {
  return {
    loading: state.notifications.loading,
    newNotificationloading: state.notifications.newNotificationloading,
    notificationResults: state.notifications.searchResults,
    newNotificationsResults: state.notifications.newNotificationsResults,
  };
};

const mapDispatchToProps: IPropsFromDispatch = {
  getAllNotifications: notificationActions.getAllNotifications,
  getNewNotifications: notificationActions.getNewNotifications,
  updateNotificationsStatus: notificationActions.updateNotificationsStatus,
  updateNotificationsStatusState:
    notificationActions.updateNotificationsStatusState,
};

const NotificationListing = connect(
  mapStateToDispatch,
  mapDispatchToProps
)(NotificationHistoryListing);

export default withRouter(NotificationListing);
