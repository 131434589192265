import {
  generateTheme,
  Palette,
  SFAlert,
  SFAuthenticationProvider,
} from "shootingflow.core";
import * as React from "react";
import { Outlet } from "react-router-dom";

// components
import Layout from "./components/layout/layout";

// styles
import "./App.css";
import { Container, ThemeProvider } from "@mui/material";
import { AxiosInterceptor } from "./utils/axios";
function App() {
  let paletteInstance: Palette = Palette.getInstance();
  paletteInstance.generatePalette();
  let theme = React.useMemo(
    () => generateTheme(paletteInstance.palette),
    [paletteInstance.palette]
  );
  return (
    // TODO :: improve fallback
    <ThemeProvider theme={theme}>
      <React.Suspense fallback={<>...</>}>
        <SFAuthenticationProvider>
          <AxiosInterceptor>
            <div
              style={{
                backgroundColor: paletteInstance.palette.background.primary,
              }}
            >
              <Container maxWidth="xl" sx={{ height: "100%" }}>
                <Layout>
                  <Outlet />
                </Layout>
              </Container>
              <SFAlert />
            </div>
          </AxiosInterceptor >
        </SFAuthenticationProvider>
      </React.Suspense>
    </ThemeProvider >
  );
}

export default App;
