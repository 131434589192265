import axios from 'axios';
import { useLogout } from 'shootingflow.core'
import { useEffect } from 'react';

const url = process.env.REACT_APP_BASE_URL;

const instance = axios.create({
    baseURL: url,
});

export const AxiosInterceptor = ({ children }: any) => {
    const logout = useLogout();

    useEffect(() => {
        // after request is made check API responce
        instance.interceptors.response.use((response: any) => {
            return response;
        }, (error: any) => {
            if (error.response.status === 401) { // logout user
                logout.signOut();
                logout.userManager.signinRedirect()
            }
            else {
                return Promise.reject(error);
            }
        });
    }, [])
    return children;
}

export default instance;



