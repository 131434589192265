import { format } from "date-fns";
import { INotifcation } from "../../services/notification/notification.services.types";
import {
  Container,
  TTypography,
  NGrid,
  STypography,
  SBox,
} from "./notification.styles";
import Popover from "@mui/material/Popover";
import { Typography, Box } from "@mui/material";
import Avatar from "@mui/material/Avatar";
import translation from "../../translations/fr.json";
import React from "react";

export interface NotificationComponentProps {
  notification: INotifcation;
}

// this function will generate an avatar color based on each userName
function convertStringToColor(name: string) {
  if (name == null || name.length == 0) name = "User";
  let hash = 0;
  let i;
  for (i = 0; i < name.length; i += 1) {
    hash = name.charCodeAt(i) + ((hash << 5) - hash);
  }
  let color = "#";
  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }
  return color;
}

function handleTime(msgDate: string) {
  const todydate = new Date();
  var msgdate = new Date(msgDate);
  const daysBetween = Math.floor(
    (todydate.valueOf() - msgdate.valueOf()) / 86400000
  );
  if (daysBetween > 31) {
    return format(msgdate, "dd-MM-yyyy");
  } else {
    if (daysBetween > 0) {
      return daysBetween === 1
        ? translation.notification.since +
            " " +
            daysBetween +
            " " +
            translation.notification.day
        : translation.notification.since +
            " " +
            daysBetween +
            " " +
            translation.notification.days;
    } else {
      const hours = todydate.getHours() - msgdate.getHours();
      if (hours > 1 && hours < 24) {
        return (
          translation.notification.since +
          " " +
          hours +
          " " +
          translation.notification.hours
        );
      } else {
        let timeInMin = Math.floor(
          (todydate.valueOf() - msgdate.valueOf()) / 60000
        );
        return timeInMin > 59
          ? translation.notification.since + " " + translation.notification.hour
          : translation.notification.since +
              " " +
              timeInMin +
              " " +
              translation.notification.minutes;
      }
    }
  }
}

export default function NotificationCard(props: NotificationComponentProps) {
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <>
      <Container>
        <Box>
          <Avatar
            aria-owns={open ? "mouse-over-popover" : undefined}
            aria-haspopup='true'
            onMouseEnter={handlePopoverOpen}
            onMouseLeave={handlePopoverClose}
            sx={{
              bgcolor: convertStringToColor(props.notification.authorName),
            }}
          >
            {props.notification.authorName
              ? props.notification.authorName.charAt(0)
              : "U"}
          </Avatar>
          <Popover
            id='mouse-over-popover'
            sx={{
              pointerEvents: "none",
            }}
            open={open}
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            onClose={handlePopoverClose}
            disableRestoreFocus
          >
            <Typography sx={{ p: 1 }}>
              {props.notification.authorName}
            </Typography>
          </Popover>
        </Box>
        <NGrid>
          <STypography>{props.notification.message}</STypography>
          <TTypography>
            {handleTime(props.notification.created.toString()).toString()}
          </TTypography>
        </NGrid>
      </Container>
    </>
  );
}
