import { INotificationState } from "./notification.reducer.types";
import { NotificationActionTypesConstants } from "../../constants/notification.constants";
import { NotificationActionsTypes } from "../../actions/notification/notification.actions.types";
import { INotifcation } from "../../../services/notification/notification.services.types";

const initialProjectState: INotificationState = {
    loading: false,
    newNotificationloading: false,
}
export const notificationReducer = (state: INotificationState = initialProjectState,
    action: NotificationActionsTypes): INotificationState => {
    switch (action.type) {
        case NotificationActionTypesConstants.GET_NOTIFICATIONS_REQUEST:
            return { ...state, loading: true, };
        case NotificationActionTypesConstants.GET_NOTIFICATIONS_SUCCESS:
            let result = action.loadMore
                ? [
                    ...(state.searchResults?.result as INotifcation[]),
                    ...(action.payload?.result as INotifcation[]),
                ]
                : (action.payload?.result as INotifcation[]);
            return {
                ...state,
                loading: false,
                searchResults: {
                    totalCount: action.payload.totalCount,
                    currentPage: action.payload.currentPage,
                    result: result,
                },
                error: false,
            };
        case NotificationActionTypesConstants.GET_NOTIFICATIONS_FAILURE:
            return { ...state, loading: false, error: true }
        case NotificationActionTypesConstants.GET_NEW_NOTIFICATIONS_REQUEST:
            return { ...state, newNotificationError: true, };
        case NotificationActionTypesConstants.GET_NEW_NOTIFICATIONS_SUCCESS:
            let resultNew = action.loadMore
                ? [
                    ...(state.newNotificationsResults?.result as INotifcation[]),
                    ...(action.payload?.result as INotifcation[]),
                ]
                : (action.payload?.result as INotifcation[]);
            return {
                ...state,
                newNotificationloading: false,
                newNotificationsResults: {
                    totalCount: action.payload.totalCount,
                    currentPage: action.payload.currentPage,
                    result: resultNew,
                },
                newNotificationError: false,
            };
        case NotificationActionTypesConstants.GET_NEW_NOTIFICATIONS_FAILURE:
            return { ...state, newNotificationloading: false, newNotificationError: true }
        case NotificationActionTypesConstants.UPDATE_NOTIFICATIONS_STATUS_REQUEST:
            return { ...state, loading: true, };
        case NotificationActionTypesConstants.UPDATE_NOTIFICATIONS_STATUS_SUCCESS:
            return { ...state, loading: false, error: false };
        case NotificationActionTypesConstants.UPDATE_NOTIFICATIONS_STATUS_FAILURE:
            return { ...state, loading: false, error: true };
        case NotificationActionTypesConstants.UPDATE_NOTIFICATIONS_STATUS_STATE_REQUEST:
            return { ...state, loading: true, };
        case NotificationActionTypesConstants.UPDATE_NOTIFICATIONS_STATUS_STATE_SUCCESS:
            return {
                ...state,
                newNotificationsResults: {
                    totalCount: 0,
                    currentPage: 1,
                    result: [],
                },
                newNotificationloading: false,
                newNotificationError: false
            };
        default:
            return state
    }
}