export enum SearchActionTypesConstants {
    /* Get products list after making a search */
    GET_SEARCHED_PRODUCTS_REQUEST = 'GET_SEARCHED_PRODUCTS_REQUEST ',
    GET_SEARCHED_PRODUCTS_SUCCESS = 'GET_SEARCHED_PRODUCTS_SUCCESS',
    GET_SEARCHED_PRODUCTS_FAILURE = 'GET_SEARCHED_PRODUCTS_FAILURE',
    /* Get associated products for a product*/
    GET_LINKED_PRODUCTS_REQUEST = 'GET_LINKED_PRODUCTS_REQUEST ',
    GET_LINKED_PRODUCTS_SUCCESS = 'GET_LINKED_PRODUCTS_SUCCESS',
    GET_LINKED_PRODUCTS_FAILURE = 'GET_LINKED_PRODUCTS_FAILURE',
    /* Get visible products for a product*/
    GET_VISIBLE_PRODUCTS_REQUEST = 'GET_VISIBLE_PRODUCTS_REQUEST ',
    GET_VISIBLE_PRODUCTS_SUCCESS = 'GET_VISIBLE_PRODUCTS_SUCCESS',
    GET_VISIBLE_PRODUCTS_FAILURE = 'GET_VISIBLE_PRODUCTS_FAILURE',
    /* Reset linked and visible products for a product if different cards are clicked*/
    RESET_PRODUCTS = 'RESET_PRODUCTS',

};
