import styled from "@mui/material/styles/styled";
import { Box, Typography, Grid } from "@mui/material/";
import { Palette } from "shootingflow.core";

let paletteInstance: Palette = Palette.getInstance();

type STypography = {
  readonly pt: string;
};

export const ScrollContainer = styled(Box)({
  overflowY: "scroll",
  width: "100%",
  height: "100%",
  maxHeight: "130rem",
  margin: "auto",
  marginTop: "3%",
  justifyContent: "center",
  alignItems: "center",
  flexDirection: "column",
  background: paletteInstance.palette.background.secondary,
  ".MuiSvgIcon-root": {
    color: paletteInstance.palette.text.primary,
  },
  ".MuiOutlinedInput-root": {
    height: "3rem",
    color: paletteInstance.palette.text.primary,
  },
  "@media (max-width:903px)": {
    width: "100%",
  },
});

export const STypography = styled(Typography)<STypography>`
  padding-top: ${(props) => props.pt};
`;
export const Loader = styled(Grid)({
  justifyContent: "center",
  alignItems: "center",
});

export const EBox = styled(Box)({
  margin: "20%",
  justifyContent: "center",
  alignItems: "center",
  flexDirection: "column",
  display: "flex",
});
