import translation from "../../translations/fr.json";
import {useLogout} from 'shootingflow.core'
import {
    SSMenuItem,
    SListItemText
  } from "./sidemenu.styles";

  
function LogoutMenuItem(props:any) {
  const logout = useLogout();
    const handleLogoutMenu = (event: any) => {
        logout.signOut();
        logout.userManager.signoutRedirect(logout.userManager._tokenClient);
        props.handleLogoutMenu();
      }

    return (
        <SSMenuItem onClick={(e) => handleLogoutMenu(e)}>
          <SListItemText>
            {translation.navigation.setting.logout}
          </SListItemText>
      </SSMenuItem>
    );
}

export default LogoutMenuItem