import React, { Component } from 'react';
import { Grid } from '@mui/material'
import Header from '../navigation/header'
type LayoutProps = {};

export default class Layout extends Component<LayoutProps> {
    state = {};

    render() {
        return (
            <Grid spacing={1} direction={'column'} container justifyContent={"stretch"}>
                <Grid item sx={{height:'auto'}}>
                    <Header />
                </Grid>
                <Grid sx={{flexGrow:1}} item>{this.props.children}</Grid>
            </Grid>)
            ;
    }
}
