export const baseService = {
  getHeaders,
};

export function getHeaders(): any {
  let token = sessionStorage.getItem(
    `oidc.user:${process.env.REACT_APP_SFAUTH_AUTHORITY}:${process.env.REACT_APP_SFAUTH_CLIENTID}`
  );
  if (token) {
    let user = JSON.parse(token);
    return {
      "Content-Type": "application/json",
      "Authorization": "Bearer " + user.access_token,
    };
  } 
}
