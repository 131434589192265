import { ISearchState } from "./search.reducer.types";
import { SearchActionTypesConstants } from "../../constants/search.constants";
import { SearchActionsTypes } from "../../actions/search/search.actions.types";
import { ISearchProductListing } from "../../../services/search/search.services.types";

const initialProjectState: ISearchState = {
    loading: false,
    linkedProductsLoading: false,
    visibleProductsLoading: false,
}
export const searchReducer = (state: ISearchState = initialProjectState,
    action: SearchActionsTypes): ISearchState => {
    switch (action.type) {
        case SearchActionTypesConstants.GET_SEARCHED_PRODUCTS_REQUEST:
            return { ...state, loading: true, };
        case SearchActionTypesConstants.GET_SEARCHED_PRODUCTS_SUCCESS:
            return {
                ...state,
                loading: false,
                searchResults: {
                    ...action.payload,
                    currentPage: action.payload?.currentPage,
                    result: action.payload?.result as ISearchProductListing[],
                    totalQueryCount: action.payload?.totalQueryCount,
                    totalShootedProductsCount: action.payload?.totalShootedProductsCount
                },
                linkedProducts: undefined,
                visibleProducts: undefined,
            };
        case SearchActionTypesConstants.GET_SEARCHED_PRODUCTS_FAILURE:
            return { ...state, loading: false }
        case SearchActionTypesConstants.GET_LINKED_PRODUCTS_REQUEST:
            return { ...state, linkedProductsLoading: true };
        case SearchActionTypesConstants.GET_LINKED_PRODUCTS_SUCCESS:
            return {
                ...state,
                linkedProductsLoading: false,
                linkedProducts: action.payload,
                selectedProduct: action.selectedProduct
            };
        case SearchActionTypesConstants.GET_LINKED_PRODUCTS_FAILURE:
            return { ...state, linkedProductsLoading: false, error: true };
        case SearchActionTypesConstants.GET_VISIBLE_PRODUCTS_REQUEST:
            return { ...state, visibleProductsLoading: true };
        case SearchActionTypesConstants.GET_VISIBLE_PRODUCTS_SUCCESS:
            return {
                ...state,
                visibleProductsLoading: false,
                visibleProducts: action.payload,
                selectedProduct: action.selectedProduct
            };
        case SearchActionTypesConstants.GET_VISIBLE_PRODUCTS_FAILURE:
            return { ...state, visibleProductsLoading: false, error: true };
        case SearchActionTypesConstants.RESET_PRODUCTS:
            return { ...state, linkedProducts: undefined, visibleProducts: undefined };

        default:
            return state
    }
}
