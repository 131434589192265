import { EProductActions } from "../../../services/catalog/catalog.services.types";
import { MediaActionTypes } from "../../actions/media/media.actions.types";
import { MediaActionTypesConstants } from "../../constants/media.constants";
import { ImageState } from "./media.reducer.types";

const initialMediastate: ImageState = {
    loading: false
}

export const mediaReducer = (state: ImageState = initialMediastate,
    action: MediaActionTypes): ImageState => {
    switch (action.type) {
        case MediaActionTypesConstants.DOWNLOAD_BOARD_SUCCESS:
            return {
                ...state, actionResult: {
                    action: EProductActions.download_barcode,
                    success: true
                }
            }
        case MediaActionTypesConstants.DOWNLOAD_BOARD_FAILURE:
            return {
                ...state, actionResult: {
                    action: EProductActions.download_barcode,
                    success: false
                }
            }
        case MediaActionTypesConstants.GET_VISIBLE_ASSOCIATED_LOOKS_REQUEST:
            return { ...state, loadingLooks: true };
        case MediaActionTypesConstants.GET_VISIBLE_ASSOCIATED_LOOKS_SUCCESS:
            return { ...state, loadingLooks: false, looks: action.payload };
        case MediaActionTypesConstants.GET_VISIBLE_ASSOCIATED_LOOKS_FAILURE:
            return { ...state, loadingLooks: false };
        case MediaActionTypesConstants.SET_VISIBLE_LOOKS_REQUEST:
            return { ...state, loadingLooks: true };
        case MediaActionTypesConstants.SET_VISIBLE_LOOKS_SUCCESS:
        case MediaActionTypesConstants.SET_VISIBLE_LOOKS_FAILURE:
            return { ...state, loadingLooks: false };
        case MediaActionTypesConstants.RESET_VISIBLE_LOOKS:
            return { ...state, looks: [] };
        default:
            return state

    }
}
