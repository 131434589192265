import instance  from '../../utils/axios';
import { getHeaders } from '../base.services';
import { INotificationListing, ISearchCriteria } from './notification.services.types';

export const notificationService = {
    getAllNotification,
    getNewNotification,
    updateNotificationsStatus,
};

/**
 * Get notification listing
 * @param params search criteria
 * @returns a list of notifications + paging data
 */
function getAllNotification(params: ISearchCriteria): Promise<INotificationListing> {
    let headers = getHeaders();
    let config = { headers: headers };
    return instance
        .post(`notifications/all`, params, config)
        .then((response : any) => {
            return response.data;
        })
        .catch((error: any) => {
            // TODO :: add interceptor to handle common errors such as 401 
            return undefined;
        });
}

/**
 * Get new notification listing
 * @param params search criteria
 * @returns a list of new notifications + paging data
 */
function getNewNotification(params: ISearchCriteria): Promise<INotificationListing> {
    let headers = getHeaders();
    let config = { headers: headers };
    return instance
        .post(`notifications/new`, params, config)
        .then((response : any) => {
            return response.data;
        })
        .catch((error: any) => {
            // TODO :: add interceptor to handle common errors such as 401 
            return undefined;
        });
}

/**
 * Update the notification status to viewed after Getting new notification listed
 * @param ids notifications' ids that will set as viewed
 * @returns status
 */
function updateNotificationsStatus(ids: Array<number>): Promise<string> {
    let headers = getHeaders();
    let config = { headers: headers };
    return instance
        .post(`notifications/set-viewed/`, ids, config)
        .then((response : any) => {
            return response.data;
        })
        .catch((error: any) => {
            // TODO :: add interceptor to handle common errors such as 401 
            return undefined;
        });
}