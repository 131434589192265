const variables = {
  // DesktopFontSize
  d10px: "0.63rem",
  d11px: "0.68rem",
  d12px: "0.75rem",
  d13px: "0.81rem",
  d14px: "0.88rem",
  d15px: "0.93rem",
  d16px: "1rem",
  d17px: "1.06rem",
  d18px: "1.13rem",
  d19px: "1.18rem",
  d20px: "1.25rem",
  d21px: "1.31rem",
  d22px: "1.37rem",
  d23px: "1.43rem",
  d24px: "1.5rem",
  d25px: "1.56rem",
  d26px: "1.62rem",
  d27px: "1.68rem",
  d28px: "1.75rem",
  d29px: "1.81rem",
  d30px: "1.88rem",
  d31px: "1.93rem",
  d32px: "2rem",
  d33px: "2.06rem",
  d34px: "2.12rem",

  // MobileFontSize
  m10px: "0.63em",
  m11px: "0.68em",
  m12px: "0.75em",
  m13px: "0.81em",
  m14px: "0.88em",
  m15px: "0.93em",
  m16px: "1em",
  m17px: "1.06em",
  m18px: "1.13em",
  m19px: "1.18em",
  m20px: "1.25em",
  m21px: "1.31em",
  m22px: "1.37em",
  m23px: "1.43em",
  m24px: "1.5em",
  m25px: "1.56em",
  m26px: "1.62em",
  m27px: "1.68em",
  m28px: "1.75em",
  m29px: "1.81em",
  m30px: "1.88em",
  m31px: "1.93em",
  m32px: "2em",
  m33px: "2.06em",
  m34px: "2.12em",
};

export default variables;
