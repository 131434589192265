/* Project DTOs */
export interface IProjectProduct {
    id: number;
    reference: string;
    projectId: number;
    product: string;
    thumbnail: string;
    barcode: string;
    hasImages: boolean;
    hasLook: boolean;
    imagesCount: number;
    commentInProject?: string;
    // TOCHECK :: will new API transform this to a Date
    availabilityDate?: string;
    inventory: number;
    linkedProducts: null;
    inventoryStatus: IInventoryHistory;
    properties: Array<IProductProperty>;
}

export interface IInventoryHistory {
    productId: number;
    productName?: string;
    productDescription?: string;
    // TOCHECK :: will new API version transform this into an enum
    status: string;
}

export interface IProductProperty {
    name: string;
    value: string;
    searchable: boolean;
}

export interface IDocument {
    document: any;
    name: string;
}

export enum EProjectActions {
    none = 0,
    delete,
    member,
}

/* Actions that can be performed on a product */
export enum EProductActions {
    none = 0,

    // sessionMenu
    add_selection,
    delete,
    validate,
    retouch_product,
    move_product,
    synchronize,
    upload,
    export_images,
    export_product,
    link_product,
    move_images,

    //
    download_barcode,
    download_images,

    update_image_label,
    update_image_rate,
    //
    reset,
    disprove,
}

export enum EView {
    none,
    catalog,
    shootList,
}

export interface IImageList {
    url: string;
    title: string;
    thumbnail: string;
    projectTitle: string;
}

export interface IProjectImages {
    name: string;
    value: IImageList[];
}

export const PAGE_SIZE = 10;
export const GENERIC_PROJECT_ID = 0;
