import {IProjectState} from './project.reducer.types';
import {
    EProjectActions,
    EProjectActionTypesConstants,
} from './../../constants/project.constants';
import {ProjectActionTypes} from '../../actions/project/project.actions.types';
import {ILastProjectsOverview} from '../../../services/project/project.services.types';

const initialProjectState: IProjectState = {
    loading: false,
};
export const projectReducer = (
    state: IProjectState = initialProjectState,
    action: ProjectActionTypes,
): IProjectState => {
    switch (action.type) {
        case EProjectActionTypesConstants.GET_ALL_PROJECT_REQUEST:
            return {...state, loading: true};
        case EProjectActionTypesConstants.GET_ALL_PROJECT_SUCCESS:
            return {
                loading: false,
                listing: action.payload,
            };
        case EProjectActionTypesConstants.GET_ALL_PROJECT_FAILURE:
            return {...state, loading: false};

        case EProjectActionTypesConstants.GET_LAST_PROJECT_REQUEST:
            return {...state, loading: true};
        case EProjectActionTypesConstants.GET_LAST_PROJECT_SUCCESS:
            return {
                loading: false,
                lastProject: action.payload,
            };
        case EProjectActionTypesConstants.GET_LAST_PROJECT_FAILURE:
            return {...state, loading: false};

        case EProjectActionTypesConstants.DELETE_PROJECT_REQUEST:
            return {...state, loadingDelete: true};
        case EProjectActionTypesConstants.DELETE_PROJECT_SUCCESS:
            let newListing = state.listing?.filter(
                (p) => p.projectId !== action.projectId,
            );
            return {
                ...state,
                loadingDelete: false,
                listing: newListing,
                lastProject: {
                    ...state.lastProject,
                    result: state.lastProject?.result.filter(
                      (p) => p.projectId !== action.projectId,
                    ),
                } as ILastProjectsOverview,
                actionResult: {
                    action: EProjectActions.delete,
                    success: true,
                },
            };
        case EProjectActionTypesConstants.DELETE_PROJECT_FAILURE:
            return {
                ...state,
                loadingDelete: false,
                actionResult: {
                    action: EProjectActions.delete,
                    success: false,
                },
            };

        case EProjectActionTypesConstants.CREATE_PROJECT_REQUEST:
            return {...state, loading: true};
        case EProjectActionTypesConstants.CREATE_PROJECT_SUCCESS:
            return {
                ...state,
                project: action.payload,
                loading: false,
                actionResult: {
                    action: EProjectActions.create,
                    success: true,
                },
            };
        case EProjectActionTypesConstants.CREATE_PROJECT_FAILURE:
            return {
                ...state,
                loading: false,
                actionResult: {
                    action: EProjectActions.create,
                    success: false,
                },
            };

        case EProjectActionTypesConstants.GET_SHOOTING_TYPES_REQUEST:
            return {...state, loading: true};
        case EProjectActionTypesConstants.GET_SHOOTING_TYPES_SUCCESS:
            return {
                ...state,
                loading: false,
                shootingTypes: action.payload,
                actionResult: {
                    action: EProjectActions.type,
                    success: true,
                },
            };
        case EProjectActionTypesConstants.GET_SHOOTING_TYPES_FAILURE:
            return {
                ...state,
                loading: false,
                actionResult: {
                    action: EProjectActions.type,
                    success: false,
                },
            };

        case EProjectActionTypesConstants.UPLOAD_FILE_REQUEST:
            return {...state, loading: true};
        case EProjectActionTypesConstants.UPLOAD_FILE_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
            };
        case EProjectActionTypesConstants.GET_PROJECT_DETAIL_REQUEST:
            return {...state, loading: true};
        case EProjectActionTypesConstants.GET_PROJECT_DETAIL_SUCCESS:
            return {
                ...state,
                loading: false,
                project: action.payload,
                actionResult: {
                    action: EProjectActions.detail,
                    success: true,
                },
            };
        case EProjectActionTypesConstants.GET_PROJECT_DETAIL_FAILURE:
            return {
                ...state,
                loading: false,
                actionResult: {
                    action: EProjectActions.detail,
                    success: false,
                },
            };

        case EProjectActionTypesConstants.UPDATE_PROJECT_DETAIL_REQUEST:
            return {...state, loading: true};
        case EProjectActionTypesConstants.UPDATE_PROJECT_DETAIL_SUCCESS:
            return {
                ...state,
                loading: false,
                project: action.payload,
                actionResult: {
                    action: EProjectActions.update,
                    success: true,
                },
            };
        case EProjectActionTypesConstants.UPDATE_PROJECT_DETAIL_FAILURE:
            return {
                ...state,
                loading: false,
                actionResult: {
                    action: EProjectActions.update,
                    success: false,
                },
            };

        case EProjectActionTypesConstants.ADD_MEMBERS_REQUEST:
            return {...state, loading: true};

        case EProjectActionTypesConstants.ADD_MEMBERS_SUCCESS:
            return {
                ...state,
                loading: false,
                members: action.payload,
                actionResult: {
                    action: EProjectActions.addMember,
                    success: true
                }
            }
        case EProjectActionTypesConstants.ADD_MEMBERS_FAILURE:
            return {
                ...state,
                loading: false,
                actionResult: {
                    action: EProjectActions.addMember,
                    success: false
                }
            }
        case EProjectActionTypesConstants.GET_MEMBERS_REQUEST:
            return {...state, loadingMembers: true};
        case EProjectActionTypesConstants.GET_MEMBERS_SUCCESS:
            return {
                ...state,
                loadingMembers: false,
                members: action.payload,
                actionResult: {
                    action: EProjectActions.member,
                    success: true,
                },
            };
        case EProjectActionTypesConstants.GET_MEMBERS_FAILURE:
            return {
                ...state,
                loadingMembers: false,
                actionResult: {
                    action: EProjectActions.member,
                    success: false,
                },
            };

        default:
            return state;
    }
};
