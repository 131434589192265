import {IFilters, IImage, IImagesByFolder, IMetaData} from '../../../services/session/session.services.types';
import {SessionActionTypes} from '../../actions/session/session.actions.types'
import {SessionActionTypesConstants} from '../../constants/session.constants';
import {SessionState} from './session.reducer.types'
import {EProductActions} from "../../../services/catalog/catalog.services.types";

const initialSessionstate: SessionState = {
    loadingFolders: false,
    loadingImages: false,
    images: [],
}

export const sessionReducer = (state: SessionState = initialSessionstate,
    action: SessionActionTypes): SessionState => {
    switch (action.type) {
        case SessionActionTypesConstants.GET_FOLDERS_REQUEST:
            return { ...state, loadingFolders: true };
        case SessionActionTypesConstants.GET_FOLDERS_SUCCESS:
            return { ...state, loadingFolders: false, folders: action.payload };
        case SessionActionTypesConstants.GET_FOLDERS_FAILURE:
            return { ...state, loadingFolders: false };
        case SessionActionTypesConstants.GET_FOLDERS_IMAGES_REQUEST:
            return { ...state, loadingImages: true };
        case SessionActionTypesConstants.GET_FOLDERS_IMAGES_SUCCESS:
            return { ...state, loadingImages: false, images: action.payload as Array<IImagesByFolder> };
        case SessionActionTypesConstants.RESET_FOLDERS_IMAGES:
            return { ...state, loadingImages: false, images: [] as Array<IImagesByFolder> };
        case SessionActionTypesConstants.GET_FOLDERS_IMAGES_FAILURE:
            return { ...state, loadingImages: false };
        case SessionActionTypesConstants.SET_SELECTED_FOLDERS:
            return { ...state, selectedFolders: action.payload };

        /* Synchronize ShootingFlow */
        case SessionActionTypesConstants.SYNCHRONIZE_SF_REQUEST:
            return { ...state, loadingSynchronize: true }
        case SessionActionTypesConstants.SYNCHRONIZE_SF_SUCCESS:
            return {
                ...state,
                loadingSynchronize: false,
                actionResult: {
                    success: true,
                    action: EProductActions.synchronize
                }
            }
        case SessionActionTypesConstants.SYNCHRONIZE_SF_FAILURE:
            return {
                ...state,
                loadingSynchronize: false,
                actionResult: {
                    success: false,
                    action: EProductActions.synchronize
                }
            }

        /* Export Image */
        case SessionActionTypesConstants.EXPORT_IMAGES_REQUEST:
            return {
                ...state,
                loadingExportImage: true
            }
        case SessionActionTypesConstants.EXPORT_IMAGES_SUCCESS:
            return {
                ...state,
                loadingExportImage: false,
                actionResult: {
                    success: true,
                    action: EProductActions.export_images
                }
            }
        case SessionActionTypesConstants.EXPORT_IMAGES_FAILURE:
            return {
                ...state,
                loadingExportImage: false,
                actionResult: {
                    success: false,
                    action: EProductActions.export_images
                }
            }

        /* Export Image to Merch */
        case SessionActionTypesConstants.EXPORT_TO_MERCH_REQUEST:
            return { ...state, loadingExportMerch: true }
        case SessionActionTypesConstants.EXPORT_TO_MERCH_SUCCESS:
            return {
                ...state,
                loadingExportMerch: false,
                actionResult: {
                    success: true,
                    action: EProductActions.export_product
                }
            }
        case SessionActionTypesConstants.EXPORT_TO_MERCH_FAILURE:
            return {
                ...state,
                loadingExportMerch: false,
                actionResult: {
                    success: false,
                    action: EProductActions.export_product
                }
            }

        case SessionActionTypesConstants.SET_FILTERS:
            return { ...state, selectedFilters: action.payload };

        case SessionActionTypesConstants.RESET_FILTERS:
            return { ...state, selectedFilters: {} as IFilters }

        case SessionActionTypesConstants.SELECT_IMAGES_REQUEST:
            return { ...state, loadingSelect: true };
        case SessionActionTypesConstants.SELECT_IMAGES_SUCCESS:
            return {
                ...state,
                images:
                  state.images?.map((file) => {
                      return {
                          ...file,
                          value: file.value.map((image) => {
                              if (action.mediaIds.includes(image.id)) return {
                                  ...image,
                                  isSelected: action.value
                              }
                              else return image
                          })
                      }
                  }),
                loadingSelect: false,
                actionResult: {
                    action: EProductActions.add_selection,
                    success: true
                }
            };
        case SessionActionTypesConstants.SELECT_IMAGES_FAILURE:
            return {
                ...state,
                loadingSelect: false,
                actionResult: {
                    action: EProductActions.add_selection,
                    success: false
                }
            };
        case SessionActionTypesConstants.VALIDATE_IMAGES_REQUEST:
            return { ...state, loadingValidate: true };
        case SessionActionTypesConstants.VALIDATE_IMAGES_SUCCESS:
            return {
                ...state,
                images:
                    state.images?.map((file) => {
                        return {
                            ...file,
                            value: file.value.map((image) => {
                                if (action.mediaIds.includes(image.id)) return {
                                    ...image,
                                    isValid: action.value
                                }
                                else return image
                            })
                        }
                    }),
                loadingValidate: false,
                actionResult: {
                    action: EProductActions.validate,
                    success: true
                }
            };
        case SessionActionTypesConstants.VALIDATE_IMAGES_FAILURE:
            return {
                ...state, loadingValidate: false, actionResult: {
                    action: EProductActions.validate,
                    success: false
                }
            };

        case SessionActionTypesConstants.CHANGE_RATE_REQUEST:
            return {...state, loading: true}

        case SessionActionTypesConstants.CHANGE_RATE_SUCCESS:
            return {
                ...state,
                loading: false,
                images:
                  state.images?.map((file) => {
                      return {
                          ...file,
                          value: file.value.map((image: IImage) => {
                              if (image.id === action.imageId) return {
                                      ...image,
                                  metasData: image.metasData === null ? [action.rate] : image.metasData.find((data: IMetaData) => data.name === 'Rating') ? image.metasData.map((data: IMetaData) => data.name === 'Rating' ? action.rate : data) : [...image.metasData, action.rate]
                                  }
                              else return image
                          })
                      }
                  }),
                actionResult: {
                    success: true,
                    action: EProductActions.update_image_rate
                }
            }

        case SessionActionTypesConstants.CHANGE_RATE_FAILURE:
            return {
                ...state,
                loading: false,
                actionResult: {
                    success: false,
                    action: EProductActions.update_image_rate
                }
            }
case SessionActionTypesConstants.CHANGE_LABEL_REQUEST:
            return {...state, loading: true}

        case SessionActionTypesConstants.CHANGE_LABEL_SUCCESS:
            return {
                ...state,
                loading: false,
                images:
                  state.images?.map((file) => {
                      return {
                          ...file,
                          value: file.value.map((image: IImage) => {
                              if (image.id === action.imageId) return {
                                  ...image,
                                  metasData: image.metasData === null ? [action.label] : image.metasData.find((data: IMetaData) => data.name === 'Label') ? image.metasData.map((data: IMetaData) => data.name === 'Label' ? action.label : data) : [...image.metasData, action.label]
                              }
                              else return image
                          })
                      }
                  }),
                actionResult: {
                    success: true,
                    action: EProductActions.update_image_label
                }
            }

        case SessionActionTypesConstants.CHANGE_LABEL_FAILURE:
            return {
                ...state,
                loading: false,
                actionResult: {
                    success: false,
                    action: EProductActions.update_image_label
                }
            }

        case SessionActionTypesConstants.MOVE_IMAGE_REQUEST:
            return {...state, loading: true}

        case SessionActionTypesConstants.MOVE_IMAGE_SUCCESS:
            return {
                ...state,
                loading: false,
                actionResult: {
                    success: true,
                    action: EProductActions.move_images
                }
            }
        case SessionActionTypesConstants.MOVE_IMAGE_FAILURE:
            return {
                ...state,
                loading: false,
                actionResult: {
                    success: false,
                    action: EProductActions.move_images
                }
            }
        case SessionActionTypesConstants.RESET_ALL:
            return {
                ...state,
                selectedFolders: undefined,
                images: undefined,
                folders: undefined
            }
        default:
            return state

    }
}
