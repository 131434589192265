import {EInventoryAction, EInventoryActionTypesConstants} from "../../constants/inventory.constants";
import {IInventoryState} from './inventory.reducer.types';
import {InventoryActionTypes} from "../../actions/inventory/inventory.actions.types";

const initialInventoryState: IInventoryState = {
  loading: false
}

export const inventoryReducer = (state: IInventoryState = initialInventoryState, action: InventoryActionTypes): IInventoryState => {
  switch (action.type) {
    case EInventoryActionTypesConstants.SET_STATUS_REQUEST:
      return {loading: true}

    case EInventoryActionTypesConstants.SET_STATUS_SUCCESS:
      return {
        loading: false,
        actionResult: {
          action: EInventoryAction.set,
          success: true
        }
      }

    case EInventoryActionTypesConstants.SET_STATUS_FAILURE:
      return {
        loading: false,
        actionResult: {
          action: EInventoryAction.set,
          success: false
        }
      }

    default:
      return state;
  }
}
