import { ICatalogResult, ICatalogState } from './catalog.reducer.types';
import { ECatalogAction, ECatalogActionTypesConstants } from '../../constants/catalog.constants';
import { CatalogActionsTypes } from '../../actions/catalog/catalog.actions.types';
import { IProjectProduct } from '../../../services/catalog/catalog.services.types';
import { IProductListing, ISearchProductListing } from '../../../services/search/search.services.types';

const initialProjectState: ICatalogState = {
    loading: false,
};
export const catalogReducer = (
    state: ICatalogState = initialProjectState,
    action: CatalogActionsTypes,
): ICatalogState => {
    switch (action.type) {
        /* Handle getting products listing */
        case ECatalogActionTypesConstants.GET_PRODUCTS_REQUEST:
            return { ...state, loading: true };
        case ECatalogActionTypesConstants.GET_PRODUCTS_SUCCESS:
            return {
                ...state,
                loading: false,
                listing: { ...action.payload },
            };
        case ECatalogActionTypesConstants.GET_PRODUCTS_FAILURE:
            return { ...state, loading: false };
        /* Handle getting linked products */
        case ECatalogActionTypesConstants.GET_LINKED_PRODUCTS_REQUEST:
            return { ...state, loading: true };
        case ECatalogActionTypesConstants.GET_LINKED_PRODUCTS_SUCCESS:
            return { ...state, loading: false, linkedProducts: action.payload };
        case ECatalogActionTypesConstants.GET_LINKED_PRODUCTS_FAILURE:
            return { ...state, loading: false };
        case ECatalogActionTypesConstants.CLEAR_LINKED_PRODUCTS:
            return {
                loading: false,
                listing: state.listing,
                filters: state.filters,
                searchQueryParams: state.searchQueryParams,
            };
        /* Find products */
        case ECatalogActionTypesConstants.FIND_PRODUCTS_REQUEST:
            return { ...state, loadingSearch: true };
        case ECatalogActionTypesConstants.FIND_PRODUCTS_SUCCESS:
            let result = action.loadMore
                ? [
                    ...(state.searchResults?.result as IProjectProduct[]),
                    ...(action.payload?.result as IProjectProduct[]),
                ]
                : (action.payload?.result as IProjectProduct[]);
            return {
                ...state,
                loadingSearch: false,
                searchResults: {
                    ...action.payload,
                    currentPage: action.payload?.currentPage,
                    result: result,
                    totalQueryCount: action.payload?.totalQueryCount,
                    totalShootedProductsCount:
                        action.payload?.totalShootedProductsCount,
                },
            };
        case ECatalogActionTypesConstants.FIND_PRODUCTS_FAILURE:
            return { ...state, loadingSearch: false };
        case ECatalogActionTypesConstants.CLEAR_FOUND_PRODUCTS:
            return {
                listing: state.listing,
                loading: false,
                loadingSearch: false,
                linkedProducts: state.linkedProducts,
                filters: state.filters,
                searchQueryParams: state.searchQueryParams,
            };
        /*Link & Unlink products */
        case ECatalogActionTypesConstants.LINK_PRODUCT:
            let linkedProducts =
                state.linkedProducts && state.linkedProducts.length > 0
                    ? [action.payload, ...state.linkedProducts]
                    : [action.payload];
            return { ...state, linkedProducts: linkedProducts };
        case ECatalogActionTypesConstants.UNLINK_PRODUCT:
            return {
                ...state,
                linkedProducts: state.linkedProducts?.filter(
                    (product) => product.id !== action.payload,
                ),
            };
        /* Save linked products */
        case ECatalogActionTypesConstants.SAVE_LINKED_PRODUCTS_REQUEST:
            return { ...state, loading: true };
        case ECatalogActionTypesConstants.SAVE_LINKED_PRODUCTS_SUCCESS:
            return {
                loading: false,
                listing: {
                    ...state.listing,
                    result: state.listing?.result?.map((product: IProductListing | ISearchProductListing) => {
                        if (product.id === action.productId && (product as ISearchProductListing).linkedProducts !== null) {
                            (product as ISearchProductListing).linkedProducts = action.linkedProductsLength !== 0
                            return product as ISearchProductListing
                        } else
                            return product as ISearchProductListing
                    }) as ISearchProductListing[]
                },
                filters: state.filters,
                searchQueryParams: state.searchQueryParams,
                actionResult: {
                    action: ECatalogAction.add_look,
                    success: true
                }
            };
        case ECatalogActionTypesConstants.SAVE_LINKED_PRODUCTS_FAILURE:
            return {
                loading: false,
                listing: state.listing,
                filters: state.filters,
                searchQueryParams: state.searchQueryParams,
                actionResult: {
                    action: ECatalogAction.add_look,
                    success: false
                }
            };
        /* Delete products */
        case ECatalogActionTypesConstants.DELETE_PRODUCTS_REQUEST:
            return { ...state, loading: true };
        case ECatalogActionTypesConstants.DELETE_PRODUCTS_SUCCESS:
        case ECatalogActionTypesConstants.DELETE_PRODUCTS_FAILURE:
            return { ...state, loading: false };
        /* Update product shoot */
        case ECatalogActionTypesConstants.UPDATE_PRODUCT_SHOOT_REQUEST:
            return { ...state, loading: true };
        case ECatalogActionTypesConstants.UPDATE_PRODUCT_SHOOT_SUCCESS:
            return {
                ...state,
                listing: {
                    ...state.listing,
                    result: (state.listing?.result as IProjectProduct[]).map(
                        (product) => {
                            if (product.id === action.payload)
                                product.hasImages = !product.hasImages;
                            return product;
                        },
                    ),
                    totalShootedProductsCount: action.shooted
                        ? (state.listing?.totalShootedProductsCount as number) +
                        1
                        : (state.listing?.totalShootedProductsCount as number) -
                        1,
                },
                loading: false,
            };
        case ECatalogActionTypesConstants.UPDATE_PRODUCT_SHOOT_FAILURE:
            return { ...state, loading: false };
        /* Get filters */
        case ECatalogActionTypesConstants.GET_FILTERS_REQUEST:
            return { ...state, loadingFilters: true };
        case ECatalogActionTypesConstants.GET_FILTERS_SUCCESS:
            return {
                ...state,
                filters: action.payload,
                loadingFilters: false,
            };
        case ECatalogActionTypesConstants.GET_FILTERS_FAILURE:
            return { ...state, loadingFilters: false };
        /* Set search query params */
        case ECatalogActionTypesConstants.SET_SEARCH_QUERY_PARAMS:
            return {
                ...state,
                searchQueryParams: action.payload,
            };

        /* import catalog */
        case ECatalogActionTypesConstants.IMPORT_CATALOG_REQUEST:
            return { ...state, loading: true };
        case ECatalogActionTypesConstants.IMPORT_CATALOG_SUCCESS:
            return {
                ...state,
                loading: false,
                catalogImportedSuccessfully: true,
            };
        case ECatalogActionTypesConstants.IMPORT_CATALOG_FAILURE:
            return {
                ...state,
                loading: false,
                catalogImportedSuccessfully: false,
            };
        /* add product  */
        case ECatalogActionTypesConstants.Add_PRODUCT_REQUEST:
            return { ...state, loading: true };
        case ECatalogActionTypesConstants.Add_PRODUCT_SUCCESS:
            return {
                ...state,
                loading: false,
                productAddedSuccessfully: true,
            };
        case ECatalogActionTypesConstants.Add_PRODUCT_FAILURE:
            return {
                ...state,
                loading: false,
                productAddedSuccessfully: false,
            };

        /* Get all images */
        case ECatalogActionTypesConstants.GET_ALL_IMAGES_REQUEST:
            return { ...state, loading: true }
        case ECatalogActionTypesConstants.GET_ALL_IMAGES_SUCCESS:
            return {
                ...state,
                loading: false,
                images: action.payload,
                actionResult: {
                    action: ECatalogAction.images,
                    success: true
                }
            }
        case ECatalogActionTypesConstants.GET_ALL_IMAGES_FAILURE:
            return {
                ...state,
                loading: false,
                actionResult: {
                    action: ECatalogAction.images,
                    success: false
                }
            }
        case ECatalogActionTypesConstants.IMPORT_SHOOTING_LIST_REQUEST:
            return { ...state, loading: true };
        case ECatalogActionTypesConstants.IMPORT_SHOOTING_LIST_SUCCESS:
            return {
                ...state,
                loading: false,
                shootingListImportedSuccessfully: true,
            };
        case ECatalogActionTypesConstants.IMPORT_SHOOTING_LIST_FAILURE:
            return {
                ...state,
                loading: false,
                shootingListImportedSuccessfully: false,
            };

        /* add products to project */
        case ECatalogActionTypesConstants.ADD_PRODUCTS_REQUEST:
            return { ...state, loading: true };
        case ECatalogActionTypesConstants.ADD_PRODUCTS_SUCCESS:
            return {
                ...state,
                loading: false,
                productAddedSuccessfullyToProject: true,
            };
        case ECatalogActionTypesConstants.ADD_PRODUCTS_FAILURE:
            return {
                ...state,
                loading: false,
                productAddedSuccessfullyToProject: false,
            };
        case ECatalogActionTypesConstants.GENERATE_BARCODES_FAILURE:
            return {
                ...state,
                actionResult: {
                    success: false,
                    action: ECatalogAction.generate_barcodes
                }
            }
        case ECatalogActionTypesConstants.RESET_ACTIONRESULT:
            return {
                ...state,
                actionResult: {} as ICatalogResult
            }
        default:
            return state;
    }
};
