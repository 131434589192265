import {ISettingState} from './setting.reducer.types';
import {
    ESettingAction,
    ESettingActionTypesConstants,
} from '../../constants/setting.constants';
import {SettingActionType} from '../../actions/setting/setting.actions.types';

const initialSettingState: ISettingState = {
    loading: false,
};

export const settingReducer = (
    state: ISettingState = initialSettingState,
    action: SettingActionType,
): ISettingState => {
    switch (action.type) {
        case ESettingActionTypesConstants.GET_KEYS_REQUEST:
            return {...state, loading: true};
        case ESettingActionTypesConstants.GET_KEYS_SUCCESS:
            return {
                ...state,
                loading: false,
                keys: action.payload,
                actionResult: {
                    action: ESettingAction.key,
                    success: true,
                },
            };

        case ESettingActionTypesConstants.GET_KEYS_FAILURE:
            return {
                ...state,
                loading: false,
                actionResult: {
                    action: ESettingAction.key,
                    success: false,
                },
            };

        case ESettingActionTypesConstants.GET_SETTING_REQUEST:
            return {...state, loading: true};
        case ESettingActionTypesConstants.GET_SETTING_SUCCESS:
            return {
                ...state,
                loading: false,
                setting: action.payload,
                actionResult: {
                    action: ESettingAction.setting,
                    success: true,
                },
            };
        case ESettingActionTypesConstants.GET_SETTING_FAILURE:
            return {
                ...state,
                loading: false,
                actionResult: {
                    action: ESettingAction.setting,
                    success: false,
                },
            };

        case ESettingActionTypesConstants.GET_PROFILE_REQUEST:
            return {...state, loading: true};
        case ESettingActionTypesConstants.GET_PROFILE_SUCCESS:
            return {
                ...state,
                loading: false,
                profile: action.payload,
                actionResult: {
                    action: ESettingAction.profile,
                    success: true,
                },
            };
        case ESettingActionTypesConstants.GET_PROFILE_FAILURE:
            return {
                ...state,
                loading: false,
                actionResult: {
                    action: ESettingAction.profile,
                    success: false,
                },
            };

        case ESettingActionTypesConstants.UPDATE_PROFILE_REQUEST:
            return {...state, loading: true};
        case ESettingActionTypesConstants.UPDATE_PROFILE_SUCCESS:
            return {
                ...state,
                loading: false,
                profile: action.payload,
                actionResult: {
                    action: ESettingAction.updateProfile,
                    success: true,
                },
            };
        case ESettingActionTypesConstants.UPDATE_PROFILE_FAILURE:
            return {
                ...state,
                loading: false,
                actionResult: {
                    action: ESettingAction.updateProfile,
                    success: false,
                },
            };

        case ESettingActionTypesConstants.GET_NOTIFICATION_SETTINGS_REQUEST:
            return {...state, loading: true};
        case ESettingActionTypesConstants.GET_NOTIFICATION_SETTINGS_SUCCESS:
            return {
                ...state,
                loading: false,
                notificationSettings: action.payload,
                actionResult: {
                    action: ESettingAction.getNotificationSettings,
                    success: true,
                },
            };
        case ESettingActionTypesConstants.GET_NOTIFICATION_SETTINGS_FAILURE:
            return {
                ...state,
                loading: false,
                notificationSettings: [],
                actionResult: {
                    action: ESettingAction.getNotificationSettings,
                    success: false,
                },
            };
        case ESettingActionTypesConstants.UPDATE_NOTIFICATION_SETTINGS_REQUEST:
            return {...state, loading: true};
        case ESettingActionTypesConstants.UPDATE_NOTIFICATION_SETTINGS_SUCCESS:
            return {
                ...state,
                loading: false,
                actionResult: {
                    action: ESettingAction.getNotificationSettings,
                    success: true,
                },
            };
        case ESettingActionTypesConstants.UPDATE_NOTIFICATION_SETTINGS_FAILURE:
            return {
                ...state,
                loading: false,
                actionResult: {
                    action: ESettingAction.getNotificationSettings,
                    success: false,
                },
            };
        default:
            return state;
    }
};
