import styled from '@mui/material/styles/styled';
import { Box, IconButton } from '@mui/material';
import { Palette } from 'shootingflow.core';
let paletteInstance: Palette = Palette.getInstance();

export const SIconButton = styled(IconButton)({
  padding: '0.5rem',
  // background: paletteInstance.palette.background.third,
});

export const SSIconButton = styled(SIconButton)({
  background: paletteInstance.palette.background.secondary,
  marginLeft: '0.5rem',
  '&:hover': {
    background: 'none',
  },
});

export const Container = styled(Box)({
  '.MuiOutlinedInput-root': {
    svg: {
      width: '1.5rem !important',
      height: '1.5rem !important',
    },
    input: {
      marginLeft: '0.6rem !important',
    },
  },
  '.MuiInputBase-root': {
    '@media (max-width: 468px)': {
      width: '215px !important',
    },
    '@media (max-width: 408px)': {
      width: '190px !important',
    },
    '@media (max-width: 375px)': {
      width: '175px !important',
    },
    '@media (max-width: 360px)': {
      width: '160px !important',
    },
    '@media (max-width: 280px)': {
      width: '80px !important',
    },
  },
});
