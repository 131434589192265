import { connectRouter } from 'connected-react-router';
import { combineReducers } from 'redux';
import { history } from '../helpers/history';
import { sessionReducer } from './session/session.reducer';
import {mediaReducer} from './media/media.reducer';
import { catalogReducer } from './catalog/catalog.reducer';
import {alertReducer} from "shootingflow.core";
import {projectReducer} from './project/project.reducer';
import {searchReducer} from './search/search.reducer'
import {settingReducer} from "./setting/setting.reducer";
import {notificationReducer} from "./notification/notification.reducer"
import {inventoryReducer} from './inventory/inventory.reducer'

const rootReducer = combineReducers({
    router: connectRouter(history),
    session: sessionReducer,
    catalog: catalogReducer,
    media: mediaReducer,
    alerts: alertReducer,
    projects: projectReducer,
    search: searchReducer,
    settings: settingReducer,
    notifications: notificationReducer,
    inventory: inventoryReducer
});
export type SFMediasState = ReturnType<typeof rootReducer>;

export default rootReducer;
