import {
  styled,
  Box,
  BottomNavigation,
  BottomNavigationAction,
} from "@mui/material";
import { Palette } from "shootingflow.core";
import { r768px } from "../../styles/responsive";
import variables from "../../styles/variables";

let paletteInstance: Palette = Palette.getInstance();

interface IProps {
  readonly open: boolean;
}

export const Wrapper = styled(Box)`
  width: 100%;
`;

export const ItemsContainer = styled(BottomNavigation)<IProps>`
  background-color: transparent;
  @media (max-width: 768px) {
    flex-direction: column;
    flex-flow: column nowrap;
    position: absolute;
    top: 100%;
    left: ${(props) => (props.open ? "-8px" : "-120%")};
    height: 16vh;
    width: 100%;
    margin: 0%;
    padding: 0%;
    z-index: 99999;
    justify-content: normal;
    align-items: center;
    /* transition: all 0.2s linear; */
    background-color: #282828;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 0.1rem;
  }
  @media (max-width: 600px) {
    width: 104.5%;
  }
`;

export const Item = styled(BottomNavigationAction)({
  width: "100% !important",
  height: "100% !important",
  ".MuiBottomNavigationAction-label": {
    fontSize: variables.d14px,
    color: paletteInstance.palette.text.disabled,
    "@media (max-width: 768px)": {
      fontSize: variables.m14px,
    },
  },
  ".MuiBottomNavigationAction-label.Mui-selected": {
    fontSize: variables.d14px,
    color: paletteInstance.palette.text.secondary,
    fontWeight: "bold",
    "@media (max-width: 768px)": {
      fontSize: variables.m14px,
    },
  },
});

export const Icon = styled(Box)`
  width: 30px;
  height: 55px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 99;
  display: none;
  cursor: pointer;
  top: 0;
  ${r768px({
    display: "flex",
  })}
`;
