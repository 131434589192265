export enum NotificationActionTypesConstants {
    /* Get notification listing */
    GET_NOTIFICATIONS_REQUEST = 'GET_NOTIFICATIONS_REQUEST ',
    GET_NOTIFICATIONS_SUCCESS = 'GET_NOTIFICATIONS_SUCCESS',
    GET_NOTIFICATIONS_FAILURE = 'GET_NOTIFICATIONS_FAILURE',
    /* Get new notification listing */
    GET_NEW_NOTIFICATIONS_REQUEST = 'GET_NEW_NOTIFICATIONS_REQUEST ',
    GET_NEW_NOTIFICATIONS_SUCCESS = 'GET_NEW_NOTIFICATIONS_SUCCESS',
    GET_NEW_NOTIFICATIONS_FAILURE = 'GET_NEW_NOTIFICATIONS_FAILURE',
    /* Update notifications status */
    UPDATE_NOTIFICATIONS_STATUS_REQUEST = 'UPDATE_NOTIFICATIONS_STATUS_REQUEST ',
    UPDATE_NOTIFICATIONS_STATUS_SUCCESS = 'UPDATE_NOTIFICATIONS_STATUS_SUCCESS',
    UPDATE_NOTIFICATIONS_STATUS_FAILURE = 'UPDATE_NOTIFICATIONS_STATUS_FAILURE',
    /* Update notifications state status */
    UPDATE_NOTIFICATIONS_STATUS_STATE_REQUEST = 'UPDATE_NOTIFICATIONS_STATUS_STATE_REQUEST ',
    UPDATE_NOTIFICATIONS_STATUS_STATE_SUCCESS = 'UPDATE_NOTIFICATIONS_STATUS_STATE_SUCCESS',
    UPDATE_NOTIFICATIONS_STATUS_STATE_FAILURE = 'UPDATE_NOTIFICATIONS_STATUS_STATE_FAILURE',

}