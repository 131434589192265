export enum MediaActionTypesConstants {
    /* Download board */
    DOWNLOAD_BOARD_REQUEST = 'DOWNLOAD_BOARD_REQUEST',
    DOWNLOAD_BOARD_SUCCESS = 'DOWNLOAD_BOARD_SUCCESS',
    DOWNLOAD_BOARD_FAILURE = 'DOWNLOAD_BOARD_FAILURE',
    /* Update metadata */
    UPDATE_IMAGE_METADATA_REQUEST = 'UPDATE_IMAGE_METADATA_REQUEST',
    UPDATE_IMAGE_METADATA_SUCCESS = 'UPDATE_IMAGE_METADATA_SUCCESS',
    UPDATE_IMAGE_METADATA_FAILURE = 'UPDATE_IMAGE_METADATA_FAILURE',
    /* Get visible associated looks */
    GET_VISIBLE_ASSOCIATED_LOOKS_REQUEST = 'GET_VISIBLE_ASSOCIATED_LOOKS_REQUEST',
    GET_VISIBLE_ASSOCIATED_LOOKS_SUCCESS = 'GET_VISIBLE_ASSOCIATED_LOOKS_SUCCESS',
    GET_VISIBLE_ASSOCIATED_LOOKS_FAILURE = 'GET_VISIBLE_ASSOCIATED_LOOKS_FAILURE',
    /* Set visible looks */
    SET_VISIBLE_LOOKS_REQUEST = "SET_VISIBLE_LOOKS_REQUEST",
    SET_VISIBLE_LOOKS_SUCCESS = "SET_VISIBLE_LOOKS_SUCCESS",
    SET_VISIBLE_LOOKS_FAILURE = "SET_VISIBLE_LOOKS_FAILURE",
    RESET_VISIBLE_LOOKS = "RESET_VISIBLE_LOOKS"
};
