export enum EProjectActionTypesConstants {
    GET_ALL_PROJECT_REQUEST = 'GET_ALL_PROJECT_REQUEST',
    GET_ALL_PROJECT_SUCCESS = 'GET_ALL_PROJECT_SUCCESS',
    GET_ALL_PROJECT_FAILURE = 'GET_ALL_PROJECT_FAILURE',

    GET_LAST_PROJECT_REQUEST = 'GET_LAST_PROJECT_REQUEST',
    GET_LAST_PROJECT_SUCCESS = 'GET_LAST_PROJECT_SUCCESS',
    GET_LAST_PROJECT_FAILURE = 'GET_LAST_PROJECT_FAILURE',

    DELETE_PROJECT_REQUEST = 'DELETE_PROJECT_REQUEST',
    DELETE_PROJECT_SUCCESS = 'DELETE_PROJECT_SUCCESS',
    DELETE_PROJECT_FAILURE = 'DELETE_PROJECT_FAILURE',

    GET_PROJECT_DETAIL_REQUEST = 'GET_PROJECT_DETAIL_REQUEST',
    GET_PROJECT_DETAIL_SUCCESS = 'GET_PROJECT_DETAIL_SUCCESS',
    GET_PROJECT_DETAIL_FAILURE = 'GET_PROJECT_DETAIL_FAILURE',

    UPDATE_PROJECT_DETAIL_REQUEST = 'UPDATE_PROJECT_DETAIL_REQUEST',
    UPDATE_PROJECT_DETAIL_SUCCESS = 'UPDATE_PROJECT_DETAIL_SUCCESS',
    UPDATE_PROJECT_DETAIL_FAILURE = 'UPDATE_PROJECT_DETAIL_FAILURE',

    CREATE_PROJECT_REQUEST = 'CREATE_PROJECT_REQUEST',
    CREATE_PROJECT_SUCCESS = 'CREATE_PROJECT_SUCCESS',
    CREATE_PROJECT_FAILURE = 'CREATE_PROJECT_REQUEST',

    GET_SHOOTING_TYPES_REQUEST = 'GET_SHOOTING_TYPES_REQUEST',
    GET_SHOOTING_TYPES_SUCCESS = 'GET_SHOOTING_TYPES_SUCCESS',
    GET_SHOOTING_TYPES_FAILURE = 'GET_SHOOTING_TYPES_FAILURE',

    UPLOAD_FILE_REQUEST = 'UPLOAD_FILE_REQUEST',
    UPLOAD_FILE_SUCCESS = 'UPLOAD_FILE_SUCCESS',
    UPLOAD_FILE_FAILURE = 'UPLOAD_FILE_FAILURE',

    ADD_MEMBERS_REQUEST = 'ADD_MEMBERS_REQUEST',
    ADD_MEMBERS_SUCCESS = 'ADD_MEMBERS_SUCCESS',
    ADD_MEMBERS_FAILURE = 'ADD_MEMBERS_FAILURE',

    GET_MEMBERS_REQUEST = 'GET_MEMBERS_REQUEST',
    GET_MEMBERS_SUCCESS = 'GET_MEMBERS_SUCCESS',
    GET_MEMBERS_FAILURE = 'GET_MEMBERS_FAILURE',
}

export enum EProjectActions {
    none = 0,
    delete = 1,
    create = 2,
    update = 3,
    detail = 4,
    type = 5,
    member = 6,
    addMember = 7,
}
