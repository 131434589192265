import React, { Component } from "react";

// styles
import {
  Menu,
  Toolbar,
  Container,
  Badge,
  ClickAwayListener,
  Tooltip,
} from "@mui/material";
import { SFIcon, ESFIcons } from "shootingflow.core";
import {
  SAppBar,
  Center,
  LogoImage,
  Right,
  Left,
  Mobile,
} from "./header.styles";
import {
  SMenu,
  SSMenuItem,
  SListItemText,
  SSNotificationMenuItem,
  SSNotificationMenuItemButton,
  SSSNotificationMenuItemButton,
} from "./sidemenu.styles";
import { SSIconButton } from "../common/searchbox.styles";
import NotificationListing from "../notification/notificationInfiniteScroll";
// components
import TopMenu from "./topmenu";
import SearchBox from "../common/searchbox";

// texts
import translation from "../../translations/fr.json";

// etc
import { withRouter } from "../../utils/hooks/withRouterHook";
// import { containsSpecialCharacters } from "../../utils/stringsHelper";
import { INotificationListing } from "../../services/notification/notification.services.types";
import { connect } from "react-redux";
import { SFMediasState } from "../../redux/reducers/rootReducer";
import { notificationActions } from "../../redux/actions/notification/notification.actions";

import LogoutMenuItem from "./logoutmenuitem";

interface HeaderProps extends IPropsFromDispatch {
  router: any;
}

interface IPropsFromDispatch {
  newNotificationsResults?: INotificationListing;
  getNewNotifications(page: number, loadMore: boolean): void;
  updateNotificationsStatusState(): void;
}

type HeaderState = {
  searchQuery: string;
  anchorElNav: null;
  anchorNotifNav: null;
  clickAwayState: boolean;
};

class Header extends Component<HeaderProps, HeaderState> {
  constructor(props: HeaderProps) {
    super(props);
    this.updateSearchQuery = this.updateSearchQuery.bind(this);
    this.clearSearchQuery = this.clearSearchQuery.bind(this);
    this.submitSearchQuery = this.submitSearchQuery.bind(this);
  }

  state: HeaderState = {
    searchQuery: "",
    anchorElNav: null,
    anchorNotifNav: null,
    clickAwayState: false,
  };

  updateSearchQuery(newSearchQuery: string): void {
    this.setState({ searchQuery: newSearchQuery });
  }

  clearSearchQuery(): void {
    this.setState({ searchQuery: "" });
  }

  submitSearchQuery(event: React.KeyboardEvent<HTMLDivElement>): void {
    if (this.state.searchQuery && this.state.searchQuery.trim().length > 0) {
      this.props.router.navigate(`/search/${this.state.searchQuery}`);
    }
  }

  handleOpenNavMenu = (event: { currentTarget: any }) =>
    this.setState({ anchorElNav: event.currentTarget });
  handleCloseNavMenu = () => this.setState({ anchorElNav: null });

  handleOpenNotificationMenu = (event: { currentTarget: any }) =>
    this.setState({
      anchorNotifNav: event.currentTarget,
      clickAwayState: true,
    });
  handleCloseNotificationMenu = () => this.setState({ anchorNotifNav: null });

  handleClickAway = () => {
    if (this.state.clickAwayState === true) {
      this.props.updateNotificationsStatusState();
      this.setState({ clickAwayState: false });
    }
  };

  componentDidMount() {
    this.props.getNewNotifications(1, false);
  }

  render() {
    return (
      <SAppBar>
        <Container maxWidth='xl'>
          <Toolbar
            disableGutters
            sx={{ display: "flex", justifyContent: "space-between" }}
          >
            <Left>
              <Mobile onClick={() => this.props.router.navigate("/")}>
                <SFIcon icon={ESFIcons.Logo} size={"2rem"} />
              </Mobile>
              <Tooltip
                title={translation.tooltip.backToHomepage}
                placement='bottom-start'
              >
                <LogoImage
                  src={"/img/logo.png"}
                  alt={"Shooting Flow"}
                  onClick={() => this.props.router.navigate("/")}
                />
              </Tooltip>
            </Left>

            {this.props.router.location.pathname
              .split("/")
              .includes("projects") && // only project routes are concerned with this (if in the future we add another route x/:id)
              this.props.router.params.id && (
                <Center>
                  <TopMenu />
                </Center>
              )}

            <Right>
              <SearchBox
                updateSearchText={this.updateSearchQuery}
                submitSearchQuery={this.submitSearchQuery}
                searchText={this.state.searchQuery}
              />

              <SSIconButton
                onClick={this.handleOpenNotificationMenu}
                color='inherit'
              >
                <ClickAwayListener onClickAway={this.handleClickAway}>
                  <Badge
                    color='error'
                    badgeContent={
                      this.props.newNotificationsResults?.totalCount
                    }
                    invisible={
                      !(
                        this.props.newNotificationsResults !== undefined &&
                        this.props.newNotificationsResults.totalCount > 0 &&
                        this.props.router.location.pathname !==
                          "/notification-history"
                      )
                    }
                  >
                    <SFIcon icon={ESFIcons.NotificationsZero} size={"1.3rem"} />
                  </Badge>
                </ClickAwayListener>
              </SSIconButton>
              <SMenu
                anchorEl={this.state.anchorNotifNav}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                open={Boolean(this.state.anchorNotifNav)}
                onClose={this.handleCloseNotificationMenu}
              >
                {this.props.router.location.pathname !==
                  "/notification-history" &&
                this.props.newNotificationsResults &&
                this.props.newNotificationsResults?.totalCount > 0 ? (
                  <>
                    <SSNotificationMenuItem
                      sx={{ width: "22rem", height: "22rem" }}
                    >
                      {<NotificationListing />}
                    </SSNotificationMenuItem>
                    <SSNotificationMenuItemButton
                      onClick={() => {
                        this.props.router.navigate("/notification-history");
                        this.setState({ anchorNotifNav: null });
                      }}
                    >
                      {translation.buttons.showAll}
                    </SSNotificationMenuItemButton>
                  </>
                ) : (
                  <SSNotificationMenuItem sx={{ flexDirection: "column" }}>
                    <SListItemText>
                      {translation.notification.empty}
                    </SListItemText>
                    <SSSNotificationMenuItemButton
                      onClick={() => {
                        this.props.router.navigate("/notification-history");
                        this.setState({ anchorNotifNav: null });
                      }}
                    >
                      {translation.buttons.showAll}
                    </SSSNotificationMenuItemButton>
                  </SSNotificationMenuItem>
                )}
              </SMenu>

              <SSIconButton onClick={this.handleOpenNavMenu} color='inherit'>
                <SFIcon icon={ESFIcons.Settings} size={"1.3rem"} />
              </SSIconButton>
              <Menu
                anchorEl={this.state.anchorElNav}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                open={Boolean(this.state.anchorElNav)}
                onClose={this.handleCloseNavMenu}
              >
                <SSMenuItem
                  onClick={() => {
                    this.props.router.navigate("/settings");
                    this.handleCloseNavMenu();
                  }}
                >
                  <SListItemText>
                    {translation.navigation.setting.setting}
                  </SListItemText>
                </SSMenuItem>
                <LogoutMenuItem
                  handleLogoutMenu={() => this.handleCloseNavMenu()}
                ></LogoutMenuItem>
              </Menu>
            </Right>
          </Toolbar>
        </Container>
      </SAppBar>
    );
  }
}

const mapStateToDispatch = (state: SFMediasState) => {
  return {
    newNotificationsResults: state.notifications.newNotificationsResults,
  };
};

const mapDispatchToProps: IPropsFromDispatch = {
  getNewNotifications: notificationActions.getNewNotifications,
  updateNotificationsStatusState:
    notificationActions.updateNotificationsStatusState,
};

const HeaderView = connect(mapStateToDispatch, mapDispatchToProps)(Header);

export default withRouter(HeaderView);
