import React, { Component } from "react";
import { withRouter } from "../../utils/hooks/withRouterHook";
// styles
import { Wrapper, ItemsContainer, Item, Icon } from "./topmenu.styles";
// texts
import translation from "../../translations/fr.json";
import { ESFIcons, SFIcon } from "shootingflow.core";
import { ClickAwayListener } from "@mui/material";

type ITabMenuProps = {
  router: any;
};

type ITabMenuState = {
  value: number;
  open: boolean;
};

class TopMenu extends Component<ITabMenuProps, ITabMenuState> {
  constructor(props: ITabMenuProps) {
    super(props);
  }

  state: ITabMenuState = {
    value: 0,
    open: false,
  };

  componentDidMount() {
    let url: string = this.props.router.location.pathname.split("/");
    let value: number = 0;
    if (url.includes("lists")) value = 1;
    else if (url.includes("session")) value = 2;
    this.setState({ value });
  }

  handleChange = (event: React.SyntheticEvent, value: any) => {
    this.setState({ value });
    switch (value) {
      case 0:
        this.props.router.navigate(`projects/${this.props.router.params.id}`);
        break;

      case 1:
        this.props.router.navigate(
          `projects/${this.props.router.params.id}/lists/products`
        );
        break;

      case 2:
        this.props.router.navigate(
          `projects/${this.props.router.params.id}/session`
        );
        break;

      default:
        break;
    }
  };

  handleClickAway = () => {
    this.setState({ open: false });
  };

  onClickButton = () => {
    if (this.state.open === false) {
      this.setState({ open: true });
    } else {
      this.setState({ open: false });
    }
  };

  toggleOpen = () => {
    this.setState({ open: false });
  };

  render() {
    return (
      <ClickAwayListener onClickAway={this.handleClickAway}>
        <Wrapper>
          <ItemsContainer
            showLabels
            value={this.state.value}
            onChange={this.handleChange}
            open={this.state.open}
          >
            <Item
              onClick={this.toggleOpen}
              label={translation.navigation.menuList.brief}
            />
            <Item
              onClick={this.toggleOpen}
              label={translation.navigation.menuList.list}
            />
            <Item
              onClick={this.toggleOpen}
              label={translation.navigation.menuList.session}
            />
          </ItemsContainer>

          <Icon onClick={this.onClickButton}>
            <SFIcon icon={ESFIcons.Menu} size={"1.5rem"} />
          </Icon>
        </Wrapper>
      </ClickAwayListener>
    );
  }
}

export default withRouter(TopMenu);
