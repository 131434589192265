export enum ESettingActionTypesConstants {
    GET_KEYS_REQUEST = 'GET_KEYS_REQUEST',
    GET_KEYS_SUCCESS = 'GET_KEYS_SUCCESS',
    GET_KEYS_FAILURE = 'GET_KEYS_FAILURE',

    GET_SETTING_REQUEST = 'GET_SETTING_REQUEST',
    GET_SETTING_SUCCESS = 'GET_SETTING_SUCCESS',
    GET_SETTING_FAILURE = 'GET_SETTING_FAILURE',

    GET_PROFILE_REQUEST = 'GET_PROFILE_REQUEST',
    GET_PROFILE_SUCCESS = 'GET_PROFILE_SUCCESS',
    GET_PROFILE_FAILURE = 'GET_PROFILE_FAILURE',

    UPDATE_PROFILE_REQUEST = 'UPDATE_PROFILE_REQUEST',
    UPDATE_PROFILE_SUCCESS = 'UPDATE_PROFILE_SUCCESS',
    UPDATE_PROFILE_FAILURE = 'UPDATE_PROFILE_FAILURE',

    GET_NOTIFICATION_SETTINGS_REQUEST = 'GET_NOTIFICATION_SETTINGS_REQUEST',
    GET_NOTIFICATION_SETTINGS_SUCCESS = 'GET_NOTIFICATION_SETTINGS_SUCCESS',
    GET_NOTIFICATION_SETTINGS_FAILURE = 'GET_NOTIFICATION_SETTINGS_FAILURE',

    UPDATE_NOTIFICATION_SETTINGS_REQUEST = 'UPDATE_NOTIFICATION_SETTINGS_REQUEST',
    UPDATE_NOTIFICATION_SETTINGS_SUCCESS = 'UPDATE_NOTIFICATION_SETTINGS_SUCCESS',
    UPDATE_NOTIFICATION_SETTINGS_FAILURE = 'UPDATE_NOTIFICATION_SETTINGS_FAILURE',
}

export enum ESettingAction {
    none = 0,
    key = 1,
    setting = 2,
    profile = 3,
    updateProfile = 4,
    getNotificationSettings = 5,
    updateNotificationSettings = 6,
}
