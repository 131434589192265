export enum ECatalogActionTypesConstants {
    /* Get shoot listing */
    GET_PRODUCTS_REQUEST = 'GET_PRODUCTS_REQUEST ',
    GET_PRODUCTS_SUCCESS = 'GET_PRODUCTS_SUCCESS',
    GET_PRODUCTS_FAILURE = 'GET_PRODUCTS_FAILURE',

    /* Get associated products for a product*/
    GET_LINKED_PRODUCTS_REQUEST = 'GET_LINKED_PRODUCTS_REQUEST ',
    GET_LINKED_PRODUCTS_SUCCESS = 'GET_LINKED_PRODUCTS_SUCCESS',
    GET_LINKED_PRODUCTS_FAILURE = 'GET_LINKED_PRODUCTS_FAILURE',
    CLEAR_LINKED_PRODUCTS = 'CLEAR_LINKED_PRODUCTS',

    /* Find products */
    FIND_PRODUCTS_REQUEST = 'FIND_PRODUCTS_REQUEST ',
    FIND_PRODUCTS_SUCCESS = 'FIND_PRODUCTS_SUCCESS',
    FIND_PRODUCTS_FAILURE = 'FIND_PRODUCTS_FAILURE',
    CLEAR_FOUND_PRODUCTS = 'CLEAR_FOUND_PRODUCTS',

    /* Link & Unlink product */
    LINK_PRODUCT = 'LINK_PRODUCT ',
    UNLINK_PRODUCT = 'UNLINK_PRODUCT',

    /* Save linked products */
    SAVE_LINKED_PRODUCTS_REQUEST = 'SAVE_LINKED_PRODUCTS_REQUEST ',
    SAVE_LINKED_PRODUCTS_SUCCESS = 'SAVE_LINKED_PRODUCTS_SUCCESS',
    SAVE_LINKED_PRODUCTS_FAILURE = 'SAVE_LINKED_PRODUCTS_FAILURE',

    /* Add product manually */
    Add_PRODUCT_REQUEST = 'Add_PRODUCT_REQUEST ',
    Add_PRODUCT_SUCCESS = 'Add_PRODUCT_SUCCESS',
    Add_PRODUCT_FAILURE = 'Add_PRODUCT_FAILURE',

    /* Delete products */
    DELETE_PRODUCTS_REQUEST = 'DELETE_PRODUCTS_REQUEST ',
    DELETE_PRODUCTS_SUCCESS = 'DELETE_PRODUCTS_SUCCESS',
    DELETE_PRODUCTS_FAILURE = 'DELETE_PRODUCTS_FAILURE',

    /* Generate barcodes */
    GENERATE_BARCODES_REQUEST = 'GENERATE_BARCODES_REQUEST ',
    GENERATE_BARCODES_SUCCESS = 'GENERATE_BARCODES_SUCCESS',
    GENERATE_BARCODES_FAILURE = 'GENERATE_BARCODES_FAILURE',

    /* Update shoot status for a product */
    UPDATE_PRODUCT_SHOOT_REQUEST = 'UPDATE_PRODUCT_SHOOT_REQUEST ',
    UPDATE_PRODUCT_SHOOT_SUCCESS = 'UPDATE_PRODUCT_SHOOT_SUCCESS',
    UPDATE_PRODUCT_SHOOT_FAILURE = 'UPDATE_PRODUCT_SHOOT_FAILURE',

    /* Download images */
    DOWNLOAD_BOARD_REQUEST = 'DOWNLOAD_BOARD_REQUEST ',
    DOWNLOAD_BOARD_SUCCESS = 'DOWNLOAD_BOARD_SUCCESS',
    DOWNLOAD_BOARD_FAILURE = 'DOWNLOAD_BOARD_FAILURE',

    /* Get filters */
    GET_FILTERS_REQUEST = 'GET_FILTERS_REQUEST',
    GET_FILTERS_SUCCESS = 'GET_FILTERS_SUCCESS',
    GET_FILTERS_FAILURE = 'GET_FILTERS_FAILURE',

    /* Set query params */
    SET_SEARCH_QUERY_PARAMS = 'SET_SEARCH_QUERY_PARAMS',

    /* import catalog */
    IMPORT_CATALOG_REQUEST = 'IMPORT_CATALOG_REQUEST',
    IMPORT_CATALOG_SUCCESS = 'IMPORT_CATALOG_SUCCESS',
    IMPORT_CATALOG_FAILURE = 'IMPORT_CATALOG_FAILURE',

    /* import shooting list */
    IMPORT_SHOOTING_LIST_REQUEST = 'IMPORT_SHOOTING_LIST_REQUEST',
    IMPORT_SHOOTING_LIST_SUCCESS = 'IMPORT_SHOOTING_LIST_SUCCESS',
    IMPORT_SHOOTING_LIST_FAILURE = 'IMPORT_SHOOTING_LIST_FAILURE',

    /* Get all images */
    GET_ALL_IMAGES_REQUEST = 'GET_ALL_IMAGES_REQUEST',
    GET_ALL_IMAGES_SUCCESS = 'GET_ALL_IMAGES_SUCCESS',
    GET_ALL_IMAGES_FAILURE = 'GET_ALL_IMAGES_FAILURE',

    /* Add products to project */
    ADD_PRODUCTS_REQUEST = 'ADD_PRODUCTS_REQUEST ',
    ADD_PRODUCTS_SUCCESS = 'ADD_PRODUCTS_SUCCESS',
    ADD_PRODUCTS_FAILURE = 'ADD_PRODUCTS_FAILURE',

    RESET_ACTIONRESULT = 'RESET_ACTIONRESULT'

}

export enum ECatalogAction {
    none = 0,
    images = 1,
    add_look = 2,
    generate_barcodes
}
