import styled from '@mui/material/styles/styled';
import { Typography, ListItem, Box } from '@mui/material/';
import { Palette } from 'shootingflow.core';
let paletteInstance: Palette = Palette.getInstance();

type TTypography = {
  readonly color: string;
};

export const Container = styled(ListItem)({
  height: '15%',
  paddingLeft: '5%',
  marginBottom: '3%',
  display: 'flex',
  '@media (max-width:428px)': {
    width: '100%',
  },
});

export const NGrid = styled('div')({
  flexDirection: 'column',
  justifyContent: 'center',
  paddingLeft: '5%',
});

export const TTypography = styled(Typography)({
  color: paletteInstance.palette.text.disabled,
  width: '100%',
  overflow: 'hidden',
});
export const STypography = styled(TTypography)({
  color: paletteInstance.palette.text.secondary,
});
export const SBox = styled(Box)({
  width: '100%',
  height: '2rem',
  paddingLeft: '1rem',
  display: 'flex',
  justifyContent: 'left',
  alignItems: 'center',
  backgroundColor: paletteInstance.palette.background.secondary,
});
