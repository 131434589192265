import * as React from 'react';
import { useState } from 'react';

// styles
import { ClickAwayListener } from '@mui/material';
import { SIconButton, Container } from './searchbox.styles';
// cores
import { SFIcon, ESFIcons, SFSearchInput } from 'shootingflow.core';
// texts
import translation from '../../translations/fr.json';

interface SearchBoxProps {
  updateSearchText: (newSearchQuery: string) => void;
  submitSearchQuery: (event: React.KeyboardEvent<HTMLDivElement>) => void;
  searchText: string;
}

function SearchBox(props: SearchBoxProps) {
  const [openSearchBar, setOpenSearchBar] = useState(false);

  const handleSearchIconClick = () => {
    setOpenSearchBar(!openSearchBar);
  };
  const handelInputChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    props.updateSearchText(event.target.value);
  };

  const onEnterKeyPress = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Enter') {
      props.submitSearchQuery(event);
    }
  };
  const onClickAway = () => {
    setOpenSearchBar(false);
  };

  return (
    <ClickAwayListener onClickAway={onClickAway}>
      {openSearchBar ? (
        <Container>
          <SFSearchInput
            type='text'
            name='search'
            placeholder={translation.data.search}
            value={props.searchText}
            onChange={handelInputChange}
            onKeyPress={onEnterKeyPress}
            autoComplete='off'
            autoFocus={true}
          />
        </Container>
      ) : (
        <SIconButton onClick={handleSearchIconClick}>
          <SFIcon icon={ESFIcons.Search} size={'1.3rem'} />
        </SIconButton>
      )}
    </ClickAwayListener>
  );
}

export default SearchBox;
