import * as React from "react";
import ReactDOM from "react-dom";

// components
import App from "./App";

// styles
import "./index.css";
import { CssBaseline } from "@mui/material";

// redux
import store from "./redux/helpers/store";
import { Provider } from "react-redux";
import { BrowserRouter, Route, Routes } from "react-router-dom";

// defining routes
const Catalog = React.lazy(() => import("./views/catalogView"));
const ShootList = React.lazy(() => import("./views/shootListView"));
const Dashboard = React.lazy(() => import("./views/dashboardView"));
const AllProjects = React.lazy(() => import("./views/allProjectsView"));
const NewProject = React.lazy(() => import("./views/creatingProjectView"));
const ProjectDetails = React.lazy(() => import("./views/projectDetailsView"));
const Search = React.lazy(() => import("./views/searchView"));
const Session = React.lazy(() => import("./views/sessionView"));
const SettingsView = React.lazy(() => import("./views/settingsView"));
const NotificationHistory = React.lazy(
  () => import("./views/notificationHistoryView")
);
// this is an intermediate page between login page (created by backend) and the dashboard, this will
// ensure the token has been set before mouting the dashboard page
const SignedInView = React.lazy(() => import("./views/signedInView"));

ReactDOM.render(
  <React.StrictMode>
    <CssBaseline />
    <Provider store={store}>
      <BrowserRouter>
        <Routes>
          {/* This is our layout, most pages will use this layout  */}
          <Route path="/" element={<App />}>
            <Route index element={<Dashboard />} />
            <Route path="catalog" element={<Catalog />} />
            <Route path="signin-oidc" element={<SignedInView />} />
            <Route path="projects/:id/lists/products" element={<ShootList />} />
            <Route path="projects/:id/session" element={<Session />} />
            <Route path="create-project" element={<NewProject />} />
            <Route path="search/:keyword" element={<Search />} />
            <Route path="projects/:id" element={<ProjectDetails />} />
            <Route path="settings" element={<SettingsView />} />
            <Route
              path="notification-history"
              element={<NotificationHistory />}
            />
            <Route path="projects" element={<AllProjects />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);
