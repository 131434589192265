import { css } from "@emotion/react";

// mobile
export const r350px = (props: any) => {
  return css`
    @media only screen and (max-width: 350px) {
      ${props}
    }
  `;
};
export const r480px = (props: any) => {
  return css`
    @media only screen and (max-width: 480px) {
      ${props}
    }
  `;
};
export const r500px = (props: any) => {
  return css`
    @media only screen and (max-width: 500px) {
      ${props}
    }
  `;
};
export const r600px = (props: any) => {
  return css`
    @media only screen and (max-width: 600px) {
      ${props}
    }
  `;
};

// tablet
export const r768px = (props: any) => {
  return css`
    @media only screen and (max-width: 768px) {
      ${props}
    }
  `;
};
export const r900px = (props: any) => {
  return css`
    @media only screen and (max-width: 900px) {
      ${props}
    }
  `;
};
export const r1024px = (props: any) => {
  return css`
    @media only screen and (max-width: 1024px) {
      ${props}
    }
  `;
};

// desktop
export const r1080px = (props: any) => {
  return css`
    @media only screen and (max-width: 1080px) {
      ${props}
    }
  `;
};
export const r1200px = (props: any) => {
  return css`
    @media only screen and (max-width: 1200px) {
      ${props}
    }
  `;
};
export const r1680px = (props: any) => {
  return css`
    @media only screen and (max-width: 1680px) {
      ${props}
    }
  `;
};

// bigSize desktop
export const r1800px = (props: any) => {
  return css`
    @media only screen and (max-width: 1800px) {
      ${props}
    }
  `;
};
export const r1920px = (props: any) => {
  return css`
    @media only screen and (max-width: 1920px) {
      ${props}
    }
  `;
};
