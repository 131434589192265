export enum SessionActionTypesConstants {
    /* Get folders for a project */
    GET_FOLDERS_REQUEST = 'GET_FOLDERS_REQUEST',
    GET_FOLDERS_SUCCESS = 'GET_FOLDERS_SUCCESS',
    GET_FOLDERS_FAILURE = 'GET_FOLDERS_FAILURE',
    /* Get images for a folder */
    GET_FOLDERS_IMAGES_REQUEST = 'GET_FOLDERS_IMAGES_REQUEST',
    GET_FOLDERS_IMAGES_SUCCESS = 'GET_FOLDERS_IMAGES_SUCCESS',
    GET_FOLDERS_IMAGES_FAILURE = 'GET_FOLDERS_IMAGES_FAILURE',
    RESET_FOLDERS_IMAGES = 'RESET_FOLDERS_IMAGES',
    /* Set selected folders */
    SET_SELECTED_FOLDERS = 'SET_SELECTED_FOLDERS',

    /* Synchronize ShootingFlow */
    SYNCHRONIZE_SF_REQUEST = 'SYNCHRONIZE_SF_REQUEST',
    SYNCHRONIZE_SF_SUCCESS = 'SYNCHRONIZE_SF_SUCCESS',
    SYNCHRONIZE_SF_FAILURE = 'SYNCHRONIZE_SF_FAILURE',

    /* Export Images */
    EXPORT_IMAGES_REQUEST = 'EXPORT_IMAGES_REQUEST',
    EXPORT_IMAGES_SUCCESS = 'EXPORT_IMAGES_SUCCESS',
    EXPORT_IMAGES_FAILURE = 'EXPORT_IMAGES_FAILURE',

    EXPORT_TO_MERCH_REQUEST = 'EXPORT_TO_MERCH_REQUEST',
    EXPORT_TO_MERCH_SUCCESS = 'EXPORT_TO_MERCH_SUCCESS',
    EXPORT_TO_MERCH_FAILURE = 'EXPORT_TO_MERCH_FAILURE',

    /* Set & Reset filter */
    SET_FILTERS = 'SET_FILTERS',
    RESET_FILTERS = 'RESET_FILTERS',

    SELECT_IMAGES_REQUEST = 'SELECT_IMAGES_REQUEST',
    SELECT_IMAGES_SUCCESS = 'SELECT_IMAGES_SUCCESS',
    SELECT_IMAGES_FAILURE = 'SELECT_IMAGES_FAILURE',

    VALIDATE_IMAGES_REQUEST = 'VALIDATE_IMAGES_REQUEST',
    VALIDATE_IMAGES_SUCCESS = 'VALIDATE_IMAGES_SUCCESS',
    VALIDATE_IMAGES_FAILURE = 'VALIDATE_IMAGES_FAILURE',

    CHANGE_RATE_REQUEST = 'CHANGE_RATE_REQUEST',
    CHANGE_RATE_SUCCESS = 'CHANGE_RATE_SUCCESS',
    CHANGE_RATE_FAILURE = 'CHANGE_RATE_FAILURE',

    CHANGE_LABEL_REQUEST = 'CHANGE_LABEL_REQUEST',
    CHANGE_LABEL_SUCCESS = 'CHANGE_LABEL_SUCCESS',
    CHANGE_LABEL_FAILURE = 'CHANGE_LABEL_FAILURE',

    MOVE_IMAGE_REQUEST = 'MOVE_IMAGE_REQUEST',
    MOVE_IMAGE_SUCCESS = 'MOVE_IMAGE_SUCCESS',
    MOVE_IMAGE_FAILURE = 'MOVE_IMAGE_FAILURE',

    RESET_ALL = 'RESET_ALL'
} ;

