export enum EInventoryActionTypesConstants {
  SET_STATUS_REQUEST= 'SET_STATUS_REQUEST',
  SET_STATUS_SUCCESS= 'SET_STATUS_SUCCESS',
  SET_STATUS_FAILURE= 'SET_STATUS_FAILURE'

}

export enum EInventoryAction {
  none = 0,
  set = 1
}
