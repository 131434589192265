import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import { NotificationActionsTypes } from './notification.actions.types';
import { INotificationState } from '../../reducers/notification/notification.reducer.types';
import { NotificationActionTypesConstants } from '../../constants/notification.constants';
import { notificationService } from '../../../services/notification/notification.services';
import { ISearchCriteria } from '../../../services/notification/notification.services.types';


export const notificationActions = {
    getAllNotifications,
    getNewNotifications,
    updateNotificationsStatus,
    updateNotificationsStatusState,
}

function getAllNotifications(
    page: number,
    loadMore: boolean,
): ThunkAction<
    Promise<NotificationActionsTypes>,
    INotificationState,
    undefined,
    NotificationActionsTypes
> {
    return async (
        dispatch: ThunkDispatch<INotificationState, undefined, NotificationActionsTypes>
    ) => {
        dispatch({
            type: NotificationActionTypesConstants.GET_NOTIFICATIONS_REQUEST
        });

        let params = { currentPage: page, perPage: 10 } as ISearchCriteria;
        return (
            notificationService.getAllNotification(params)
                .then(response => {
                    return dispatch({
                        type:
                            response !== undefined
                                ? NotificationActionTypesConstants.GET_NOTIFICATIONS_SUCCESS
                                : NotificationActionTypesConstants.GET_NOTIFICATIONS_FAILURE,
                        payload: response,
                        loadMore: loadMore
                    });
                }))
    }
}

function getNewNotifications(
    page: number,
    loadMore: boolean,
): ThunkAction<
    Promise<NotificationActionsTypes>,
    INotificationState,
    undefined,
    NotificationActionsTypes
> {
    return async (
        dispatch: ThunkDispatch<INotificationState, undefined, NotificationActionsTypes>
    ) => {
        dispatch({
            type: NotificationActionTypesConstants.GET_NEW_NOTIFICATIONS_REQUEST
        });

        let params = { currentPage: page, perPage: 10 } as ISearchCriteria;
        return (
            notificationService.getNewNotification(params)
                .then(response => {
                    return dispatch({
                        type:
                            response !== undefined
                                ? NotificationActionTypesConstants.GET_NEW_NOTIFICATIONS_SUCCESS
                                : NotificationActionTypesConstants.GET_NEW_NOTIFICATIONS_FAILURE,
                        payload: response,
                        loadMore: loadMore
                    });
                }))
    }
}

function updateNotificationsStatus(
    ids: Array<number>,
): ThunkAction<
    Promise<NotificationActionsTypes>,
    INotificationState,
    undefined,
    NotificationActionsTypes
> {
    return async (
        dispatch: ThunkDispatch<INotificationState, undefined, NotificationActionsTypes>
    ) => {
        dispatch({
            type: NotificationActionTypesConstants.UPDATE_NOTIFICATIONS_STATUS_REQUEST
        });
        return (
            notificationService.updateNotificationsStatus(ids)
                .then(response => {
                    return dispatch({
                        type:
                            response !== undefined
                                ? NotificationActionTypesConstants.UPDATE_NOTIFICATIONS_STATUS_SUCCESS
                                : NotificationActionTypesConstants.UPDATE_NOTIFICATIONS_STATUS_FAILURE,
                        payload: response,
                    });
                }))
    }
}

function updateNotificationsStatusState(): ThunkAction<
    Promise<NotificationActionsTypes>,
    INotificationState,
    undefined,
    NotificationActionsTypes
> {
    return async (
        dispatch: ThunkDispatch<INotificationState, undefined, NotificationActionsTypes>
    ) => {
        dispatch({
            type: NotificationActionTypesConstants.UPDATE_NOTIFICATIONS_STATUS_REQUEST
        });
        return (dispatch({
            type: NotificationActionTypesConstants.UPDATE_NOTIFICATIONS_STATUS_STATE_SUCCESS,
        })
        )
    }
}