import { useLocation, useNavigate, useParams } from "react-router-dom";

// since withRouter is deprecated in react-router-dom v6, link : https://reactrouter.com/docs/en/v6/faq
// we've added the hook ourself to access the location in class components

export function withRouter(Component: any) {
  return (props: any) => (
    <Component
      {...props}
      router={{
        location: useLocation(),
        navigate: useNavigate(),
        params: useParams(),
      }}
    />
  );
}
