import { AppBar, Typography, Box } from "@mui/material";
import styled from "@mui/material/styles/styled";
import { Palette } from "shootingflow.core";
import variables from "../../styles/variables";
import { r768px } from "../../styles/responsive";
let paletteInstance: Palette = Palette.getInstance();

export const SAppBar = styled(AppBar)({
  padding: 0,
  backgroundColor: paletteInstance.palette.background.secondary,
  "@media (max-width: 1200px)": {
    webkitWidth: "100% !important",
  },
});

export const Right = styled(Box)`
  display: flex !important;
  align-items: center !important;
  flex: 1 !important;
  -webkit-flex: 1 !important;
  justify-content: right;
`;
export const Center = styled(Right)`
  flex: 1 !important;
  -webkit-flex: 1 !important;
  width: 100% !important;
  height: 56px !important;
  ${r768px({
    marginLeft: "0.6rem",
  })};
`;
export const Left = styled(Right)`
  flex: 1 !important;
  -webkit-flex: 1 !important;
  justify-content: left;
  ${r768px({
    flex: "0.1 !important",
    marginLeft: "0.6rem",
  })}
`;
export const LogoImage = styled("img")`
  width: 160px;
  height: 35px;
  cursor: pointer;
  ${r768px({
    fontSize: variables.m14px,
    display: "none !important",
  })}
`;

export const Mobile = styled(Box)({
  "@media (min-width:768px)": {
    display: "none",
  },
  "@media (max-width:768px)": {
    display: "block",
  },
});
export const SMobile = styled(Mobile)({
  "@media (min-width:1200px)": {
    display: "none",
  },
  "@media (max-width:1200px)": {
    display: "block",
    marginTop: "1rem",
  },
});
