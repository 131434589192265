import {
  ListItemText,
  MenuItem,
  MenuList,
  List,
  Menu,
  Box,
} from "@mui/material";
import styled from "@mui/material/styles/styled";
import { Palette } from "shootingflow.core";
import { r768px, r1200px } from "../../styles/responsive";
import variables from "../../styles/variables";
let paletteInstance: Palette = Palette.getInstance();

export const SMenuList = styled(MenuList)`
  padding: 0;
  background: ${paletteInstance.palette.common.gray600};
  width: 100%;
  height: 90vh;
  max-width: 310px;
  ${r768px({
    maxWidth: "100% !important",
    height: "100% !important",
    borderBottom: "1px solid #dcdcdc40",
    marginBottom: "2rem",
  })}
  ${r1200px({
    maxWidth: "200px",
    maxHeight: "100% !important",
  })}
`;

export const SMenuItem = styled(MenuItem)`
  padding: 1.35rem 1rem;
  ${r768px({
    padding: "1rem 1rem",
  })};
`;
export const SSMenuItem = styled(SMenuItem)`
  width: 10rem;
  height: 100%;
`;

export const SListItemText = styled(ListItemText)`
  font-size: ${variables.d14px};
  ${r768px({
    fontSize: variables.m14px,
  })}
`;

export const SSNotificationMenuItem = styled(List)({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  marginLeft: "0.3rem",
  marginRight: "0.3rem",
  cursor: "pointer",
  Width: "22rem",
});

export const SSNotificationMenuItemButton = styled(SSNotificationMenuItem)({
  "&:hover": {
    background: paletteInstance.palette.common.gray100,
  },
});

export const SSSNotificationMenuItemButton = styled(
  SSNotificationMenuItemButton
)({
  fontSize: "0.7rem",
  color: paletteInstance.palette.text.disabled,
  "&:hover": {
    backgroundColor: "transparent",
    color: paletteInstance.palette.text.primary,
  },
});

export const SMenu = styled(Menu)({
  marginTop: "1rem",
  "@media (max-width:905px)": {
    width: "100%",
  },
});

export const SBox = styled(Box)({
  width: "100%",
  height: "2rem",
  display: "flex",

  justifyContent: "left",
  alignItems: "center",
  margin: "1rem 0",
  "@media (max-width:786px)": {
    margin: ".6rem 0",
    paddingLeft: "1rem",
  },
});
